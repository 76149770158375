/* 600/16 */
/* 801/16 */
/* 1100/16 */
/* 768/16 */
#error-content {
  position: relative;
  width: auto;
  max-width: 1040px;
  padding: 30px 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
#error-content:before,
#error-content:after {
  content: " ";
  display: table;
}
#error-content:after {
  clear: both;
}
@media (max-width: 1020px) {
  #error-content {
    padding-top: 60px;
  }
}
#error-content .error-info {
  float: left;
  width: 100%;
  max-width: 460px;
  padding-top: 50px;
}
@media (max-width: 1020px) {
  #error-content .error-info {
    float: none;
    padding-top: 0;
    margin: 0 auto;
  }
}
#error-content .error-info .error-heading {
  color: #3e3e3e;
  font-size: 40px;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 25px;
}
@media (max-width: 1020px) {
  #error-content .error-info .error-heading {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
}
@media (max-width: 37.5em) {
  #error-content .error-info .error-heading {
    font-size: 30px;
  }
}
#error-content .error-info .error-text {
  font-size: 20px;
  line-height: 1.5em;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  margin-bottom: 40px;
}
@media (max-width: 1020px) {
  #error-content .error-info .error-text {
    text-align: center;
    padding: 0 10px;
    margin-bottom: 30px;
  }
}
@media (max-width: 37.5em) {
  #error-content .error-info .error-text {
    font-size: 16px;
  }
}
#error-content .error-info .error-back,
#error-content .error-info .error-home {
  display: inline-block;
  width: 180px;
  font-size: 18px;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 14px 0;
  border: 2px solid #57bf93;
}
@media (max-width: 1020px) {
  #error-content .error-info .error-back,
  #error-content .error-info .error-home {
    width: 100%;
    margin-bottom: 30px;
  }
}
#error-content .error-info .error-back.wider,
#error-content .error-info .error-home.wider {
  width: 220px;
}
@media (max-width: 1020px) {
  #error-content .error-info .error-back.wider,
  #error-content .error-info .error-home.wider {
    width: 100%;
  }
}
#error-content .error-info .error-back {
  background-color: #57bf93;
  color: #fff;
  margin-right: 25px;
}
#error-content .error-info .error-home {
  background-color: #fff;
  color: #57bf93;
}
#error-content img {
  display: block;
  float: right;
}
@media (max-width: 1020px) {
  #error-content img {
    float: none;
    width: 100%;
    max-width: 490px;
    margin: 0 auto;
  }
}
#error-content.funnel .error-info {
  padding-top: 70px;
}
@media (max-width: 1020px) {
  #error-content.funnel .error-info {
    padding-top: 0;
  }
}
